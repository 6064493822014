<template>
  <section class="one-column-text">
    <h2 class="title">
      {{ title }}
    </h2>
    <div v-html="text" class="text" />
  </section>
</template>

<script>
export default {
  name: 'OneColumnText',
  props: {
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="postcss" scoped>
.one-column-text {
  @apply flex flex-col justify-between items-stretch mx-auto max-w-screen-xl py-72 px-5;
}
.title,
.text {
  @apply text-4xl;
}
.title {
  @apply text-brand font-bold;
}
.text {
  @apply text-black font-light;
}
.text >>> ul {
  @apply list-disc ml-8;
}
.text >>> li {
  @apply mb-4;
}
</style>
