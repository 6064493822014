import { render, staticRenderFns } from "./OneColumnText.vue?vue&type=template&id=e24948f2&scoped=true&"
import script from "./OneColumnText.vue?vue&type=script&lang=js&"
export * from "./OneColumnText.vue?vue&type=script&lang=js&"
import style0 from "./OneColumnText.vue?vue&type=style&index=0&id=e24948f2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24948f2",
  null
  
)

export default component.exports