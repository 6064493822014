<template>
  <main v-if="content" class="service">
    <Hero :image="content.heroImage.url" :text="content.productName" :view="content._modelApiKey" />

    <OneColumnText
      v-if="content.title && content.text"
      :title="content.title"
      :text="content.text"
      html
    />

    <ImageGallery
      v-if="content.images && Object.entries(content.images).length > 0"
      :images="content.images"
    />
  </main>
</template>

<script>
import { mapState } from 'vuex'
import Hero from '@/components/layout/Hero'
import OneColumnText from '@/components/layout/OneColumnText'
import ImageGallery from '@/components/ImageGallery'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Product',
  components: { Hero, OneColumnText, ImageGallery },
  props: {
    product: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['servicesRoutes, equipmentRoutes']),
    content () {
      const productPath = this.product
      return this.$store.getters.productContent(productPath)
    }
  },
  watch: {
    content (val) {
      document.title = `${val.productName} | Andinor`
    }
  }
}
</script>

<style lang="postcss" scoped>
.service {
  @apply relative;
}
</style>
