<template>
  <section :class="['images', { modalMode: showModal }]">
    <div class="close" @click="closeModal">
      Cerrar
    </div>
    <VueSlickCarousel v-bind="settings">
      <template #prevArrow>
        <img :src="require('@/assets/svg/left-chevron.svg')" alt="Anterior">
      </template>

      <div v-for="(image, index) in images" :key="index" @click="imageClicked">
        <img :src="image.url" :alt="image.title">
      </div>

      <template #nextArrow>
        <img :src="require('@/assets/svg/right-chevron.svg')" alt="Anterior">
      </template>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'ImageGallery',
  components: { VueSlickCarousel },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      initialSlide: 0
    }
  },
  computed: {
    settings () {
      return (
        this.showModal ? {
          arrows: true,
          dots: true,
          initialSlide: this.initialSlide,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          touchThreshold: 5
        } : {
          arrows: true,
          dots: true,
          initialSlide: this.initialSlide,
          infinite: true,
          focusOnSelect: true,
          speed: 500,
          slidesToShow: this.images?.length > 3 ? 3 : this.images.length,
          slidesToScroll: this.images?.length > 3 ? 3 : this.images.length,
          touchThreshold: 5,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: this.images?.length > 2 ? 2 : this.images.length,
                slidesToScroll: this.images?.length > 2 ? 2 : this.images.length
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        }
      )
    }
  },
  methods: {
    imageClicked (e) {
      this.initialSlide = Number(e.target.parentNode.parentNode.parentNode.dataset.index)
      this.showModal = true
    },
    closeModal () {
      this.initialSlide = 0
      this.showModal = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.images {
  @apply relative max-w-screen-xl w-full mx-auto mb-16 bg-transparent;
  @apply transition-all duration-300 ease-in-out;
}

.close {
  @apply absolute top-0 right-0 m-4 text-white font-bold cursor-pointer;
  @apply transition-opacity duration-300 ease-in-out opacity-0 pointer-events-none;
}

.images >>> .slick-slider {
  @apply max-w-screen-xl w-full;
}

.images >>> .slick-list {
  @apply overflow-hidden;
}

.images >>> .slick-track {
  @apply flex justify-center items-center;
}

.images >>> .slick-slide {
  @apply mx-2 cursor-pointer;
}

.images >>> .slick-dots li {
  @apply w-3 h-3 border border-brand rounded-full bg-white opacity-100;
  @apply transition-colors duration-300 ease-linear;
}

.images >>> .slick-dots li.slick-active {
  @apply bg-brand;
}

.images >>> .slick-dots li button::before {
  content: '';
}

.imageText {
  @apply hidden mt-4 text-white text-center;
}

.images.modalMode {
  @apply fixed top-0 left-0 w-screen h-screen max-w-none z-50;
  @apply flex justify-center items-center bg-black bg-opacity-75;
}

.images.modalMode .close {
  @apply pointer-events-auto opacity-100;
}

.images.modalMode >>> .slick-arrow {
  @apply hidden sm:block w-8 h-8 z-50;
  @apply lg:w-12 lg:h-12;
  @apply xl:w-16 xl:h-16;
}

.images.modalMode >>> .slick-prev {
  @apply left-4 lg:left-6 xl:left-20;
}
.images.modalMode >>> .slick-next {
  @apply right-4 lg:right-6 xl:right-20;
}

.images.modalMode >>> .slick-list {
  @apply max-w-4xl mx-auto;
}

.images.modalMode .imageText {
  @apply block;
}
.images.modalMode >>> img {
  @apply m-auto;
}
</style>
